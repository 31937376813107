import { apiCallback } from 'api';
import { trackPersonalisationHeader } from 'api/utils/personalisation-header';

const definition = {
  defaultVersion: 1,
  endpoint: 'products',
  query: {
    availableDateTimeRange: '${dateTime}',
    branchId: '${branchId}',
    categoryId: '${categoryId}',
    filters: '${filters}',
    productCollectionId: '${collectionId}',
    promotionflag: '${promotionflag}',
    searchTerm: '${searchTerm}',
    size: '${size}',
    slotType: '${slotType}',
    sortBy: '${sortBy}',
    start: '${start}',
    view: 'SUMMARY',
    showRecommendations: '${showRecommendations}',
    promotionTypes: '${promotionTypes}',
    promotionId: '${promotionId}',
    trolleyId: '${orderId}',
    ignoreTrolley: '${ignoreTrolley}',
  },
  headers: { experience: 'offer-details' },
  service: 'products',
  verbs: ['get'],
};

export default {
  get: apiCallback(definition, 'get', trackPersonalisationHeader),
};
