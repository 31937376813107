import customaisle from 'api/definitions/customaisle';
import offers from 'api/definitions/offers';
import offersgroup from 'api/definitions/offersgroup';
import offerssearch from 'api/definitions/offerssearch';
import offersdetails from 'api/definitions/products-offers-details';
import products from 'api/definitions/products';
import search from 'api/definitions/search';

import { COOKIE_MONETATE_ID } from 'constants/cookies';
import { getAllCookies } from 'utils/cookie';

import { FAILED, LOADED, LOADING } from 'redux/modules/search-and-browse/actions/types';

import { redirectFromSearchResults } from 'utils/general';
import { setRequestProperty } from 'redux/modules/search-and-browse/actions/utils/set-request-property';

import { getGridItems } from 'redux/modules/search-and-browse/selectors';

import { updateSearchQueryParams } from 'redux/modules/search/actions/update-search-query-params';
import { GET_CMS_PAGE_NOT_FOUND } from 'redux/modules/cms-page/actions/types';

const notFoundStatuses = [400, 404];

// will be easier to type and remove this 'any' once the api definitions are converted to TypeScript
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const api: Record<string, { [key: string]: any }> = {
  customaisle,
  offers,
  offersgroup,
  offerssearch,
  offersdetails,
  products,
  search,
};

type Request = {
  api: string;
  body?: { [key: string]: unknown };
  method: string;
  properties?: { [key: string]: unknown };
  schema?: string;
  transform?: string;
  name?: string;
  noRedirects?: boolean;
} | null;

export const fetchComponentsAndProducts =
  ({
    request: req = undefined,
    start = 0,
    successType = LOADED,
    refinement = undefined,
    categoryId,
    categoryLevel,
  }: {
    request?: Request;
    start?: number;
    successType?: string;
    refinement?: boolean;
    categoryId?: number;
    categoryLevel?: number;
  }) =>
  (dispatch: WtrDispatch, getState: () => WtrState): unknown => {
    const cookies = getAllCookies();
    const monetateId = cookies[COOKIE_MONETATE_ID];

    const headers = monetateId
      ? {
          'monetate-id': monetateId,
        }
      : undefined;

    const { noRedirects = false } = req || { noRedirects: false };
    const state = getState();
    const {
      offers: { activeOffer: isOffer = false } = {},
      searchAndBrowse: { displayedProducts, request: searchAndBrowseRequest } = {},
    } = state;
    let request = req || searchAndBrowseRequest;

    if (start !== 0) request = setRequestProperty(request, 'start', start);

    // If products have been invalidated load a fresh page,
    // or the previously loaded total, whichever is greater
    if (displayedProducts !== 0) {
      if (getGridItems(state).length < displayedProducts) {
        request = setRequestProperty(
          request,
          'size',
          Math.min(96, Math.max(48, displayedProducts)),
        );
      }
    }

    if (typeof categoryId === 'number')
      request = setRequestProperty(request, 'category', categoryId);

    if (typeof categoryLevel === 'number')
      request = setRequestProperty(request, 'categoryLevel', categoryLevel);

    const { api: requestApi, body, method, properties, schema, transform } = request;
    const canHaveBody = method !== 'get';

    return dispatch({
      types: [LOADING, successType, FAILED],
      noRedirects,
      request,
      start,
      isOffer,
      apiCall: api[requestApi][method]({
        ...(canHaveBody && { body }),
        ...properties,
        headers,
        iHandleStatusCodes: notFoundStatuses,
      }),
      schema,
      transform,
      refinement,
    })
      .then((result = {}) => {
        const { name } = request;
        const { searchAndBrowse: { criteria, redirectUrl } = {} } = getState();

        if (redirectUrl) {
          redirectFromSearchResults(redirectUrl);
        }

        if (criteria && name === 'Search') {
          dispatch(updateSearchQueryParams());
        }

        return result;
      })
      .catch((error: { status: number }) => {
        if (notFoundStatuses.includes(error.status)) {
          return dispatch({ type: GET_CMS_PAGE_NOT_FOUND });
        }
        throw error;
      });
  };
